import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { PARTE_TRABAJO, CLASIFICACION_ESTADO_ORDEN_TRABAJO } from '@/utils/consts'

export default {
  async selectParamsLookups (Vue, idsParametros) {
    let apiCalls = []
    // Parámetros
    const apiFilterParamertos = new APIFilter()
    apiFilterParamertos.addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParamertos } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectLookups (Vue, ordenTrabajo, idparteTrabajo) {
    let apiCalls = []
    const apiFilterOrdenTrabajo = new APIFilter()
    apiFilterOrdenTrabajo.addExact('idorden_trabajo', ordenTrabajo.idorden_trabajo)
    apiCalls.push({
      name: 'selectOTTieneLineasPendientesInstalar', method: 'ordenTrabajo.selectSync', params: { filter: apiFilterOrdenTrabajo }
    })
    const apiFilterPartesPendientes = new APIFilter()
    apiFilterPartesPendientes
      .addExact('idorden_trabajo', ordenTrabajo.idorden_trabajo)
      .addExact('estado', PARTE_TRABAJO.estados.pendiente)
      .addNE('idparte_trabajo', idparteTrabajo)
    apiCalls.push({
      name: 'selectOtrosPartesPendientes', method: 'parteTrabajo.select', params: { filter: apiFilterPartesPendientes }
    })
    const apiFilterSistema = new APIFilter()
    apiFilterSistema.addExact('idsistema', ordenTrabajo.idsistema)
    apiCalls.push({
      name: 'selectSistema', method: 'sistema.selectSync', params: { filter: apiFilterSistema }
    })
    const clasificacionesEstados = [
      CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.finalizada,
      CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.pendiente
    ]
    const apiFilterFlujoFinalizado = new APIFilter()
    apiFilterFlujoFinalizado
      .addGT('estado', 0)
      .addExact('idflujo', ordenTrabajo.idflujo)
      .addExact('idestado_origen', ordenTrabajo.idest_orden_trabajo)
      .addIn('clasificacion_idestado_destino', clasificacionesEstados)
    apiCalls.push({
      name: 'selectFlujoTransicion', method: 'flujoTransicion.selectFlujoOrdenTrabajo', params: { filter: apiFilterFlujoFinalizado }
    })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.est_orden_trabajo,
        tables.orden_trabajo.idest_orden_trabajo.eq(tables.est_orden_trabajo.idest_orden_trabajo)
      )
      .where(tables.parte_trabajo.idparte_trabajo.eq(id))
      .exec()
    )[0]
  },
  async selectTecnicoSearch(Vue, search, page, usuarioIdtecnico) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('estado', 1)
      .addNE('idtecnico', usuarioIdtecnico)
      .addILike('nombre_y_apellido', search)
    const resp = await Vue.$api.call(
      'tecnico.select',
      {
        filter: apiFilter,
        page: page,
        page_size: Vue.$api.ITEMS_PER_PAGE_AUTOCOMPLETE,
      },
      {
        disableUI: false,
      },
    )
    return resp.data.result.dataset
  },
}

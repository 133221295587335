<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="tipoCierre"
      :rules="formRules.tipo_cierre"
    >
      <v-radio-group
        v-model="form.tipo_cierre"
      >
        <div
          v-for="item in TIPO_CIERRE_SUBIR_PARTE.tipos"
          :key="item"
        >
          <v-radio
            v-if="permissionTipoCierreSubirParte[item]"
            class="mt-3"
            :label="TIPO_CIERRE_SUBIR_PARTE.descripciones[item]"
            :value="item"
            :error-messages="errors"
            @click="clickTipoCierre(item)"
          />
          <b10-autocomplete
            v-if="item===TIPO_CIERRE_SUBIR_PARTE.tipos.finalizarOT"
            v-show="estadosSeleccionables.length > 1 && form.tipo_cierre === TIPO_CIERRE_SUBIR_PARTE.tipos.finalizarOT"
            v-model="form.idestado_destino"
            :items="estadosSeleccionables"
            item-value="idestado_destino"
            item-text="descripcion"
            label="Estado"
          />
          <div
            v-if="item===TIPO_CIERRE_SUBIR_PARTE.tipos.mantenerOTPendiente"
            v-show="estadosSeleccionables.length > 1 && form.tipo_cierre === TIPO_CIERRE_SUBIR_PARTE.tipos.mantenerOTPendiente"
            class="ml-4"
          >
            <v-checkbox
              v-model="form.cambiar_estado"
              label="Cambiar estado a:"
            />
            <b10-autocomplete
              v-show="form.cambiar_estado"
              v-model="form.idestado_destino"
              :items="estadosSeleccionables"
              item-value="idestado_destino"
              item-text="descripcion"
              label="Estado"
            />
          </div>

          <ValidationProvider
            v-slot="{ errors }"
            name="tecnico"
            :rules="formRules.tecnico"
          >
            <b10-autocomplete
              v-if="item===TIPO_CIERRE_SUBIR_PARTE.tipos.reasignarParteOtroTecnico"
              v-show="form.tipo_cierre === TIPO_CIERRE_SUBIR_PARTE.tipos.reasignarParteOtroTecnico"
              v-model="form.tecnico"
              :loading="isLoading"
              :items="tecnicos"
              item-text="nombre_y_apellido"
              label="Técnico"
              clearable
              :search-input.sync="tecnicoSearch"
              return-object
              no-filter
              :error-messages="errors"
            >
              <template #append-item>
                <div v-intersect="endIntersect" />
              </template>
            </b10-autocomplete>
          </ValidationProvider>
        </div>
      </v-radio-group>
    </ValidationProvider>
    <v-checkbox
      v-if="tieneLineasPendientesInstalar && form.tipo_cierre !== TIPO_CIERRE_SUBIR_PARTE.tipos.mantenerOTPendiente"
      v-model="form.albaranar_material_pendiente_instalacion"
      label="Albaranar material pendiente de instalación"
    />
    <v-subheader
      v-if="enviarDoc"
    >
      Documentación a enviar
    </v-subheader>
    <ValidationProvider
      name="docFichaSistema"
    >
      <v-checkbox
        v-if="enviarDocFichaSistema"
        v-model="form.doc_ficha_sistema"
        label="Ficha de sistema CRA"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="docFichaSistemaEmail"
      :rules="formRules.doc_ficha_sistema_email"
    >
      <v-text-field
        v-if="enviarDocFichaSistema"
        v-model="form.doc_ficha_sistema_email"
        clearable
        flat
        label="Destinatarios"
        :disabled="!form.doc_ficha_sistema"
        :error-messages="errors"
      />
    </ValidationProvider>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ParteSubirFormData'
import { PARAMETRO, TIPO_CIERRE_SUBIR_PARTE, CLASIFICACION_ESTADO_ORDEN_TRABAJO } from '@/utils/consts'
import _ from '@/utils/lodash'
import  { extend } from 'vee-validate'
import  filters from '@/utils/filters'

export default {
  mixins: [formMixin],
  props: {
    permissionTipoCierreSubirParte: {
      type: Object,
      default: () => {},
    },
    usuarioIdtecnico: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      form: {
        tipo_cierre: null,
        albaranar_material_pendiente_instalacion: true,
        doc_ficha_sistema: false,
        doc_ficha_sistema_email: '',
        tecnico: {},
        idestado_destino: null,
        cambiar_estado: false,
      },
      formRules: {
        tipo_cierre: { required: true },
        // TODO: validar que es un email correcto
        doc_ficha_sistema_email: { required_if: { target: 'docFichaSistema', value: true } },
        tecnico: { tecnicoValida: true },
      },
      TIPO_CIERRE_SUBIR_PARTE,
      CLASIFICACION_ESTADO_ORDEN_TRABAJO,
      tieneLineasPendientesInstalar: false,
      sistema: {},
      tecnicos: [],
      tecnicoSearch: '',
      isLoading: false,
      page: 0,
      searchValue: '',
      estadosDisponibles: [],
      estadosSeleccionables: [],
    }
  },
  computed: {
    enviarDocFichaSistema () {
      return this.sistema.idcentral_alarma && this.sistema.central_alarma_email
    },
    enviarDoc () {
      return this.enviarDocFichaSistema
    },
  },
  watch: {
    tecnicoSearch (value) {
      if (value)
        if (value.length < 3) {
          if (this.page > 0) {
            this.page = 0
          }
        } else {
          if (value !== this.form.tecnico){
            this.searchValue = value
            this.tecnicoDoSearch(value)
          }
        }
    },
  },
  async created () {
    extend('tecnicoValida', {
      message: 'Debe indicar un técnico al que asignar la orden de trabajo',
      validate: async (value) => {
        if (this.form.tipo_cierre === this.TIPO_CIERRE_SUBIR_PARTE.tipos.reasignarParteOtroTecnico && !value) {
          return false
        } else {
          return true
        }
      }
    })
    const parametrosNecesarios = [ PARAMETRO.parametros.ALBARANAR_MATERIAL_PENDIENTE_INSTALACION, ]
    const resp = await Data.selectParamsLookups(this, parametrosNecesarios)
    const albaranarMaterialPendienteInstalacion = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.ALBARANAR_MATERIAL_PENDIENTE_INSTALACION })
    if (albaranarMaterialPendienteInstalacion) {
      this.$set(this.form, 'albaranar_material_pendiente_instalacion', (albaranarMaterialPendienteInstalacion.valor) === '1')
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      const resp = await Data.selectLookups(
        this,
        formData.orden_trabajo,
        id,
      )
      const datasetLineasPendientes = resp.data.selectOTTieneLineasPendientesInstalar.result?.dataset[0]
      if (datasetLineasPendientes.length > 0) {
        this.tieneLineasPendientesInstalar = datasetLineasPendientes[0].tiene_lineas_pdte_instalar
      }
      this.estadosDisponibles = resp.data.selectFlujoTransicion.result.dataset
      // si no tiene permiso para finalizar parte y OT
      // o si hay mas de un parte pendiente
      const datasetOtrosPartesPendientes = resp.data.selectOtrosPartesPendientes.result?.dataset
      if (!this.hasPermFinalizarParteYOt || datasetOtrosPartesPendientes.length > 0) {
        this.$set(this.form, 'tipo_cierre', TIPO_CIERRE_SUBIR_PARTE.tipos.mantenerOTPendiente)
      } else {
        this.$set(this.form, 'tipo_cierre', TIPO_CIERRE_SUBIR_PARTE.tipos.finalizarOT)
      }
      // Forzar evento
      this.clickTipoCierre(this.form.tipo_cierre)
      this.sistema = resp.data.selectSistema.result.dataset[0]
      if (this.sistema.idcentral_alarma && this.sistema.central_alarma_email) {
        this.$set(this.form, 'doc_ficha_sistema', true)
        this.$set(this.form, 'doc_ficha_sistema_email', this.sistema.central_alarma_email)
      }
    },
    async tecnicoDoSearch (value) {
      this.isLoading = true
      this.tecnicos = await Data.selectTecnicoSearch(this, value, this.page, this.usuarioIdtecnico)
      this.isLoading = false
    },
    async endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.tecnicos.length > 0) {
        this.isLoading = true
        this.page += 1
        let masTecnicos = await Data.selectTecnicoSearch(this, this.searchValue, this.page, this.usuarioIdtecnico)
        this.tecnicos = [ ...this.tecnicos, ...masTecnicos]
        this.isLoading = false
      }
    },
    async filtarEstados (clasificacion) {
      let idEstadoOrdenDestino = null
      this.estadosSeleccionables = _.filter(this.estadosDisponibles, {clasificacion_idestado_destino: clasificacion})
      if (this.estadosSeleccionables.length === 1) {
        idEstadoOrdenDestino = this.estadosSeleccionables[0].idestado_destino
      } else if (this.estadosSeleccionables.length > 1) {
        const estadoFinalizadoDefecto = _.find(this.estadosSeleccionables, { por_defecto: true })
        if (estadoFinalizadoDefecto) {
          idEstadoOrdenDestino = estadoFinalizadoDefecto.idestado_destino
        } else {
          idEstadoOrdenDestino = this.estadosSeleccionables[0].idestado_destino
        }
      // Si eligió la opción de mantener de estado no tiene que cambiar de estado
      } else if (this.estadosSeleccionables.length === 0 && clasificacion !== this.CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.pendiente){
        this.$alert.showSnackbarError(`No existe ningún estado de orden de trabajo con clasificacion ${filters.lowercase(CLASIFICACION_ESTADO_ORDEN_TRABAJO.descripcion[clasificacion])}`)
      }
      this.$set(this.form, 'idestado_destino', idEstadoOrdenDestino)
    },
    async clickTipoCierre (value) {
      if (value == this.TIPO_CIERRE_SUBIR_PARTE.tipos.finalizarOT) {
        this.filtarEstados(this.CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.finalizada)
        this.$set(this.form, 'cambiar_estado', true)
      } else if (value == this.TIPO_CIERRE_SUBIR_PARTE.tipos.mantenerOTPendiente) {
        this.filtarEstados(this.CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.pendiente)
      }
    },
  },
}
</script>